@import '~/styles/utils';

.wrapper {
  position: relative;
  min-height: 200rem;
  min-width: 50rem;
}

.placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: placeholder-pulse 2s ease-in-out 0s infinite;

  svg {
    width: 50rem;
  }
}

@keyframes placeholder-pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}
