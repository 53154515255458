@import '~/styles/utils';

.container {
  overflow: hidden;
  position: relative;
  height: 65vh;
  height: 65vh;

  @include landscape {
    height: 100vh;
    height: 100vh;
  }
}

.imageWrap,
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  object-fit: cover;
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(180deg, transparent 0%, $color-dark-dark-blue 100%);

  @include landscape {
    left: -12.5%;
    width: 125%;
    height: 100%;
    background-image: radial-gradient(
      at top,
      transparent 37.5%,
      rgba(0, 0, 0, 0.15) 50%,
      rgba(0, 0, 0, 0.25) 62.5%,
      $color-dark-dark-blue 71%,
      $color-dark-dark-blue 87.5%
    );
  }
}

.hiddenHeroText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: white;
  opacity: 0.0001;
}
