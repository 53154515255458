@import '~/styles/utils';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-dark-dark-blue;
}

.copyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  height: 100svh;
  position: relative;
}

.header {
  color: $color-white;
  font-size: 65rem;
  font-family: $font-sans-display;
  font-style: normal;
  font-weight: 325;
  line-height: 90%;
  letter-spacing: -1.3rem;
  padding-bottom: 70rem;

  @include sm {
    font-size: 90rem;
  }

  @include landscape {
    @include title-d;
    font-size: 170rem;
  }
}

.works {
  color: $color-works-purple;
  position: relative;
}

.tm {
  font-size: 32rem;
  position: absolute;
  top: 5%;
  font-weight: 400;

  @include sm {
    font-size: 36rem;
  }

  @include landscape {
    font-size: 70rem;
    top: 25%;
  }
}

.subhead {
  @include h3-m;
  color: $color-white;
  position: absolute;
  bottom: 70rem;

  @include landscape {
    @include h3-d;
  }
}
