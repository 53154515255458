.video {
  position: relative;
  padding-top: 56.25%; // 16x9
}

.videoFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
