@import '~/styles/utils';

.indicator {
  position: fixed;
  z-index: 800;
  bottom: 0;
  left: 0;
  background: $color-exo-fluro;
  color: $color-dark-dark-blue;
  width: 150rem;
  font-size: 14rem;
  padding: 5rem 0;
  text-align: center;
  transform-origin: center center;
  transform: translate(-34rem, -26rem) rotate(45deg);
  opacity: 0.8;
  transition: opacity $speed ease-in-out;
  &:hover {
    opacity: 1;
  }
}
