@import '~/styles/utils';

.legalSection {
  padding: 100rem 20rem;
  margin-top: 60rem;

  @include landscape {
    margin: 60rem auto 0 auto;
    max-width: 1167rem;
  }
}

.title {
  @include h1-m;
  margin-bottom: 40rem;
  font-weight: 400;

  @include landscape {
    @include h1-d;
    font-weight: 400;
  }
}

.content {
  @include body-m;

  @include landscape {
    @include large-body-d;
  }

  p {
    margin-bottom: 20rem;
  }
}
