@import '~/styles/utils';

.container {
  contain: paint;
  position: relative;
}

.wellstar {
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  background: $color-white;
  margin: $side-padding 0;
  padding: 40rem 10rem;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);

  @media (min-width: 1025px) {
    p {
      max-width: 100% !important;
      font-size: 42rem !important;
    }
  }

  @media (min-width: 801px) {
    p {
      max-width: 100% !important;
      font-size: 38rem !important;
    }
  }

  @media (min-width: 600px) {
    p {
      max-width: 100% !important;
      font-size: 32rem !important;
    }
  }

  @media (min-width: 480px) {
    p {
      max-width: 400px;
      font-size: 22rem;
    }
  }

  @include landscape {
    font-size: 32rem;

    p {
      // max-width: 400px;
      // font-size: 22rem;
    }
  }
}

// .wellstarCta {}

.videoSectionContainer {
  background: $color-dark-dark-blue;
  padding-bottom: 1rem;
}
