@import '~/styles/mixins';
@import '~/styles/_vars.scss';

.container {
  isolation: isolate;
}

.placeholder {
  height: 100vh;
  background: $color-lavender;
  z-index: 5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h2 {
  @include h2-m;
  text-align: center;

  @include md {
    @include h2-d;
  }
}

.modularSectionsTop {
  background: $color-white;
  z-index: 10;
  position: relative;
}
